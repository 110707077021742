<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品ID:"
                    label-for="product_id"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.productId"
                      name="productId"
                      class="d-inline-block mr-1"
                      type="number"
                      size="sm"
                      placeholder="请输入商品ID"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="订单状态:"
                    label-for="orderStatus"
                    label-size="sm"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('product_box_status')"
                      v-model="condition.status"
                      name="orderStatus"
                      class="select-size-sm"
                      placeholder="请选择订单状态"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :order-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :order-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">
          #{{ data.item.id }}
        </template>

        <template #cell(product_id)="data">
          [{{data.item.product_id}}]{{data.item.ext.name}}
        </template>

        <!-- Column: Type -->
        <template #cell(status)="data">
          {{getCodeLabel('product_box_status', data.item.status)}}
        </template>

        <template #cell(create_time)="data">
          {{toTime(data.item.create_time)}}
        </template>

        <template #cell(creator)="data">
          {{getCodeLabel('user', data.item.creator)}}
        </template>

        <template #cell(update_time)="data">
          {{toTime(data.item.update_time)}}
        </template>

        <template #cell(updator)="data">
          {{getCodeLabel('user', data.item.updator)}}
        </template>

        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('product_box_status', data.item.status)}`"
          >
            {{ getCodeLabel('product_box_status', data.item.status) }}
          </b-badge>
        </template>

        <template #cell(stock)="data">
          <b-link :to="{name:'apps-stock-manage',query:{productId:data.item.product_id}}" target="_blank">
            <feather-icon
                icon="PackageIcon"
                size="21"
            />
          </b-link>
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'product_box'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item v-if="data.item.status===1" :to="{ name: 'apps-productbox-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.status===1" @click="changeStatus(data.item,2,'提交审核')">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <div v-if="user.role_id==17||user.role_id==18||user.role_id==14">
              <b-dropdown-item v-if="data.item.status===2" @click="changeStatus(data.item,3,'审核通过,将直接修改箱规')">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">审核通过</span>
              </b-dropdown-item>

              <b-dropdown-item v-if="data.item.status===2" @click="changeStatus(data.item,1,'审核失败')">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">审核失败</span>
              </b-dropdown-item>
            </div>

            <b-dropdown-item v-if="data.item.status!==1">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">无菜单</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import productboxUseList from './productboxUseList'
import productboxStore from './productboxStore'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import {useToast} from "vue-toastification/composition";
import {getUserData} from "@/auth/utils";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('productbox/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    }
  },
  data(){
    return{
      user:{},
      advanced_search_modal: false,
    }
  },
  setup() {
    // Register module
    const toast = useToast()
    if (!store.hasModule('productbox')) store.registerModule('productbox', productboxStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('productbox')) store.unregisterModule('productbox')
    })

    const changeStatus = function (params,status,information) {
      if (confirm("是否"+information)){
        store.dispatch('productbox/changeStatus', {id: params.box_id, status: status}).then(res => {
          if (res.data.code==0){
              toast.success("提交成功!")
          }else {
            toast.error("提交失败!")
          }
          refetchData()
        })
      }

    }

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = productboxUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      // UI
      changeStatus,
      advanced_search,

      condition,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
