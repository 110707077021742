import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function productboxUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
    { key: 'product_id', label: '商品ID', sortable: true },
    { key: 'box_quantity_source', label: '修改前单箱标准数量', sortable: true },
    { key: 'box_quantity_after', label: '修改后单箱标准数量', sortable: true },
    { key: 'reason', label: '修改原因', sortable: true },
    { key: 'attachments', label: '附件', sortable: true },
    { key: 'status', label: '状态', sortable: true },
    { key: 'create_time', label: '申请时间', sortable: true },
    { key: 'creator', label: '申请人', sortable: true },
    { key: 'update_time', label: '审核时间', sortable: true },
    { key: 'updator', label: '审核人', sortable: true },
    { key: 'stock', label: '当前库存', sortable: true },
        { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(50)
  const limitOptions = [25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('box_id')
  const isSortDirDesc = ref(true)
  const condition = ref({})

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('productbox/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        productId:condition.value.productId,
        status:condition.value.status==null?null:condition.value.status.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,
    condition,

    // Extra Filters
  }
}
